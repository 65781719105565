<template>
  <div class="audit">
    <rxNavBar title="装修申请审核" androidOrIOSFlag="true"></rxNavBar>
    <div class="head">
      <div class="search">
        <div class="search-icon" @click="goSearch"></div>
        <input type="text" placeholder="申请人、房源地址" v-model="searchContent" @keyup.enter="goSearch">
      </div>
      <div class="types">
        <div class="type" @click="isShow = !isShow" id="downList">
          {{ typeList[number] ? typeList[number].dictionaryTitle : '未审批' }}
        </div>
        <div :class="isShow?'small-icon-rotate':'small-icon'"></div>
      </div>
      <!--            &lt;!&ndash;下拉列表&ndash;&gt;-->
      <!--            <div class="selectList" v-show="isShow">-->
      <!--                <div class="select" :class="{selectActive:index===number}" @click="changeType(index)" v-for="(item,index) in typeList" :key="index">-->
      <!--                    {{item}}-->
      <!--                </div>-->
      <!--            </div>-->
    </div>
    <div class="auditDiv">
<!--      success-text="刷新成功"-->
      <van-pull-refresh v-model="refreshing"  @refresh="onRefresh"   >
        <van-list
            v-model="loading"
            :finished="finished"
            finished-text=""
            @load="this.onLoad"
        >
          <div class="stateList" v-for="(item,index) in orders" :key="index">
            <div>
              <!--                循环列表状态 -->
              <div v-if="item.approvalStatus==0" class="stateUnapproval">
                {{ item.approvalStatusStr }}
              </div>
              <div v-if="item.approvalStatus==1" class="statePassed">
                {{ item.approvalStatusStr }}
              </div>
              <div v-if="item.approvalStatus==2" class="stateRejected">
                {{ item.approvalStatusStr }}
              </div>
              <!--                循环列表头-->
              <div class="headInfo">
                <!-- afterRentStatusStr 装修 方案 验收 决算-->
                <span class="headSpan">{{ item.afterRentStatusStr }} </span>
                <div class="dot"></div>
                {{ item.roomDetailedAddress }}
              </div>
              <!--                循环列表详细信息-->
              <div class="listDetail">
                <div class="proposerDiv">申请人</div>
                <!--                    第一条竖线-->
                <div class="rule1"></div>
                <div v-if="item.approvalStatus==0" class="budgetDiv">装修预算</div>
                <div v-if="item.approvalStatus==1" class="budgetDiv">装修预算</div>
                <div v-if="item.approvalStatus==2" class="budgetDiv">租金</div>
                <!--                    第二条竖线-->
                <div class="rule2"></div>
                <div class="remouldDiv">改造户型</div>
                <!--                    获取申请人的信息-->
                <div class="proposerValueDiv">{{ item.applicationGlobaluserName }}</div>
                <!--                    获取装修费用值的信息-->
                <div class="budgetValueDiv">{{ item.maxBudgetFee }}元</div>
                <!--                    获取改造房屋的信息-->
                <div class="remouldValueDiv">{{ item.allChangHouseNum }}房间</div>
              </div>
              <!--                分割线-->
              <div class="rule3"></div>
              <!--                循环列表下部分-->
              <div class="listFloor">
                <div v-if="item.approvalStatus==0" class="createMethodsDiv">申请时间 : </div>
                <div v-if="item.approvalStatus==1" class="createMethodsDiv">通过时间 :</div>
                <div v-if="item.approvalStatus==2" class="createMethodsDiv">驳回时间 :</div>
                <div class="createTimeDiv">{{ item.addTime }}</div>
                <!--审核按钮-->
                <div>
                  <!--装修申请-->
                  <button class="btn1" v-if="item.afterRentStatus == 0 && (item.approvalStatus==0 && checkAuthList(authButtonsList,'approval'))" @click="auditDetailClick(index)">审核</button>
                  <!--方案审核-->
                  <button class="btn1" v-if="item.afterRentStatus == 12 && (item.approvalStatus==0 && checkAuthList(authButtonsOtherList,'designApprove'))" @click="auditDetailClick(index)">审核</button>
                  <!--验收审核-->
                  <button class="btn1" v-if="item.afterRentStatus == 13 && (item.approvalStatus==0 && checkAuthList(authButtonsOtherList,'acceptApprove'))" @click="auditDetailClick(index)">审核</button>
                  <!--决算审核-->
                  <button class="btn1" v-if="item.afterRentStatus == 14 && (item.approvalStatus==0 && checkAuthList(authButtonsOtherList,'finialApprove'))" @click="auditDetailClick(index)">审核</button>
                  <!--查看按钮-->
                  <button class="btn2" v-if="item.approvalStatus != 0" @click="decorationSchemeAuditCheck(item)">查看</button>
                </div>
              </div>
            </div>
          </div>

          <!--空状态显示-->
          <common-empty v-if="isEmptuyFlag"></common-empty>
        </van-list>
      </van-pull-refresh>
    </div>
    <van-popup v-model="isShow" position="bottom">
      <van-picker
          show-toolbar
          :columns="typeList"
          @cancel="isShow = false"
          :default-index="number"
          value-key="dictionaryTitle"
          @confirm="changeType"
      />
    </van-popup>
  </div>
</template>

<script>
import {
  Button, Search, DropdownMenu, DropdownItem, Divider, List, NavBar, Picker,PullRefresh,
  Popup,
} from 'vant';
import {decorationReviewListInit, getStaffFuntionModelList, queryBaseData} from "../../getData/getData";
import {checkAuthList, getStaffId, responseUtil} from "../../libs/rongxunUtil";
import rxNavBar from "../../components/rongxun/rx-navBar/navBar";

export default {
  components: {
    [Button.name]: Button,
    [Search.name]: Search,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Divider.name]: Divider,
    [NavBar.name]: NavBar,
    [List.name]: List,
    rxNavBar,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [PullRefresh.name]:PullRefresh,
  },
  data() {
    return {
      authButtonsList:[],  //装修申请权限
      authButtonsOtherList:[],  //装修验收、方法、决算审核
      refreshing: false,
      //空状态判断
      isEmptuyFlag: false,
      flag: false,
      page: 1,
      loading: false,
      finished: false,
      searchContent: '',
      number: 1,
      type: '未审批',
      typeList: [{dictionaryValue: '', dictionaryTitle: '全部审批'}],
      isShow: false,
      name: "",
      value1: "3",
      searchDate: "",
      option: [],
      datas: {
        // staff_id:'16',
        // currentPage:'1',
        // numberPage:'10',
        //每次3条
        numberPage: '3',
        approvalStatus: '0',
        user_id: '16',
        afterRentType: '0',

      },
      orders: []
    };
  },
  beforeCreate() {
    // this.initChooseTypes()
  },
  beforeRouteEnter(to, from, next) {
    //清除本地localStorage缓存
    if(from.name == null){
      localStorage.removeItem('currentLabel');
      localStorage.removeItem('type');
      localStorage.removeItem('inputValue');
      localStorage.removeItem('currentLabelPublic');
    }
    next()
  },
  created() {
    this.getStaffFuntionModelList()
    //权限获取
    this.getStaffOtherList('renovationFinal')
    this.getStaffOtherList('renovationAccept')
    this.getStaffOtherList('renovationDesign')
    this.initChooseTypes()
    let currentLabel = JSON.parse(localStorage.getItem('currentLabel')); //先读取local里存储的历史记录
    if (currentLabel != null) {
      if (currentLabel != '') {
        this.number = Number(currentLabel)
      }
    }

  },
  methods: {
    //搜索
    onSearch() {
    },
    //显示隐藏下拉菜单，监听div点击事件，实现点击空白处隐藏下拉列表
    showOrHidden: function () {
      this.isShow = !this.isShow;
      var sp = document.getElementById("downList");
      if (sp) {
        document.addEventListener('click', e => {
          if (!sp.contains(e.target)) {
            this.isShow = false;
          }
        })
      }
    },
    //返回上一层
    leftReturn() {
      this.$router.go(-1);
    },
    // 待审批点击审核，进入装修申请
    auditDetailClick(index) {
      //console.log(this.orders[index].id)

      if (this.orders[index].afterRentStatusStr == '装修') {
        this.$router.push({
          name: 'applyHouseDecoration',
          params: {id: this.orders[index].id}
        });
      } else if (this.orders[index].afterRentStatusStr == '方案') {
        this.$router.push({
          name: 'decorationSchemeAudit',
          params: {id: this.orders[index].id}
        });

      } else if (this.orders[index].afterRentStatusStr == '验收') {
        this.$router.push({
          name: 'decorationCheckAudit',
          params: {id: this.orders[index].id}
        });

      } else if (this.orders[index].afterRentStatusStr == '决算') {
        this.$router.push({
          name: 'decorationFinalAudit',
          params: {id: this.orders[index].id}
        });

      }
    },
    //装修申请方案查看
    decorationSchemeAuditCheck(item) {
      //装修
      if (item.afterRentStatus == 0) {
        this.$router.push({
          name: 'applyHouseDecoration',
          params: {id: item.id, afterRentStatus: item.afterRentStatus}
        })
        //方案
      } else if (item.afterRentStatus == 12) {
        this.$router.push({
          name: 'decorationSchemeAudit',
          params: {id: item.id, afterRentStatus: item.afterRentStatus}
        });
        //验收
      } else if (item.afterRentStatus == 13) {
        this.$router.push({
          name: 'decorationCheckAudit',
          params: {id: item.id, afterRentStatus: item.afterRentStatus}
        });
        //决算
      } else if (item.afterRentStatus == 14) {
        this.$router.push({
          name: 'decorationFinalAudit',
          params: {id: item.id, afterRentStatus: item.afterRentStatus}
        });
      }

    },

    //下拉菜单初始化选择类型
    initChooseTypes: function () {
      let that = this
      let initData = {}
      initData.dbName = ['description']
      initData.fdName = ['APPROVESTATUSMAP']
      queryBaseData(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {

          //that.typeList = response.data.data.APPROVESTATUSMAP
          let list = response.data.data.APPROVESTATUSMAP
          for (let i = 0; i < list.length; i++) {
            that.typeList.push(list[i])
          }
        })
      })
    },

    //更改选择类型
    changeType: function (value, index) {
      //将选择的类型放入本地内存中，
      if (index == '') {
        index = 0;
      }
      localStorage.setItem('currentLabel', JSON.stringify(index))
      this.flag = true
      this.finished = true;
      this.orders = [];
      this.page = 1;
      this.loading = false;
      this.finished = false;
      this.isShow = false;
      this.number = index;
      // console.log(index);
      this.type = this.typeList[index];
      this.loading = true;
      this.onLoad();
    },

    //下拉刷新
    onRefresh() {
      // 清空列表数据
         this.finished = false;
         this.orders = []
         this.page = 1
         // 重新加载数据
         // 将 loading 设置为 true，表示处于加载状态
         this.loading = true;
        this.refreshing = false
         this.onLoad();
         responseUtil.alertMsg(this,"刷新成功")
    },

    //下拉加载
    onLoad: function () {
      var that = this
      let initData = that.datas
      that.datas.approvalStatus = that.number > 0 ? that.number - 1 : ''
      //下拉刷新的页数
      initData.currentPage = that.page + ''
      initData.searchName = that.searchContent
      initData.staff_id = getStaffId()
      initData.portionAfterRentFlag = '0'
      // console.log(  "ye"+initData.currentPage)
      //console.log( this.datas.approvalStatus)
      decorationReviewListInit(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          console.log(response.data.data)
          //that.orders = response.data.data.data
          that.count = response.data.data.pageCount
          //console.log("count"+ that.count)
          let list = response.data.data.data
          for (let i = 0; i < list.length; i++) {
            that.orders.push(list[i])
          }
          if(that.refreshing){
            that.refreshing = false
          }

          // 加载状态结束
          that.loading = false;
          that.page++;
          // 数据全部加载完成
          if (that.orders.length >= that.count) {
            that.finished = true;
            //console.log(that.finished)
          }
          //判断数据是否为空，空则显示空状态图
          if (that.orders.length == 0) {
            that.isEmptuyFlag = true;
          } else {
            that.isEmptuyFlag = false;
          }
        })
      })
    },
    //搜索框查询
    goSearch() {
      this.orders = []
      this.page = 1;
      if (this.searchContent == '') {
        location.reload()
      }
      this.onLoad()
    },

    // 权限获取 - 装修申请审批
    getStaffFuntionModelList () {
      var that = this
      let data = {}
      data.staff_id = getStaffId()
      data.menuName = 'renovation_index'
      getStaffFuntionModelList(data).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.authButtonsList = response.data.data.data
        })
      })
    },
    checkAuthList,

    // 装修方案、验收、决算审批权限
    getStaffOtherList(menuName) {
      var that = this
      let data = {}
      data.staff_id = getStaffId()
      data.menuName = menuName
      getStaffFuntionModelList(data).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          // that.authButtonsOtherList = response.data.data.data
          let list = response.data.data.data
          for(let i = 0 ; i < list.length ; i++){
            that.authButtonsOtherList.push(list[i]);
          }
          console.log(that.authButtonsOtherList)
        })
      })
    },
  }
}
</script>

<style lang="less" scoped>
.nav_bar_title {
  z-index: 99;
}

.head {
  width: 100%;
  position: fixed;
  z-index: 8;
  overflow: hidden;
  background: #f8f8f8;
}

.search {
  margin: 8px 20px 0 20px;
  height: 36px;
  border-radius: 30px;
  background-color: rgba(244, 244, 244, 1);
  display: flex;
  align-items: center;
}

.search-icon {
  width: 19px;
  height: 19px;
  margin-left: 9px;
  background-image: url("../../assets/images/search-icon.png");
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
}

input {
  font-size: 14px;
  margin-left: 10px;
  background-color: rgba(244, 244, 244, 1);
  border: 0;
  width: 250px;
}

input::-webkit-input-placeholder {
  color: rgba(199, 199, 199, 1);
}

.types {
  width: 100%;
  height: 47px;
  display: flex;
}

.type {
  width: auto;
  height: 17px;
  line-height: 17px;
  text-align: left;
  color: #fe5e3a;
  margin: 15px 0 0 15px;
  color: rgba(255, 93, 59, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
}

.small-icon {
  width: 8px;
  height: 8px;
  margin: 19px 0 0 5px;
  background-image: url("../../assets/images/small-icon.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.small-icon-rotate {
  width: 8px;
  height: 8px;
  margin: 18px 0 0 5px;
  background-image: url("../../assets/images/small-icon.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  transform: rotate(180deg);
}

.choose-select {
  position: fixed;
  z-index: 6;
  padding-top: 90px;
  width: 100%;
}

.selectList {
  width: 100%;
  height: auto;
  padding: 5px 0 14px 0;
  background-color: white;
}

.select {
  width: 100%;
  height: 35px;
  line-height: 30px;
  font-size: 14px;
  text-align: left;
  margin-left: 15px;
  color: #999999;
}

.selectActive {
  color: #fe5e3a;
}

.h-line {
  height: 1px;
  margin: 0 5px 0 10px;
  background-color: white;
}

/*    下拉菜单*/
.downMenuDiv {
  width: 2.2rem;
}

.auditDiv {
  padding-top: 91px;
}

/*    循环列表DIV*/
.stateList {
  border-radius: 0.3rem;
  width: 93%;
  margin-left: 0.35rem;
  position: relative;
  margin-bottom: 0.4rem;
  background-color: #ffffff;
  padding-bottom: 0.5rem;
}

.stateUnapproval, .statePassed, .stateRejected {
  height: 0.45rem;
  width: fit-content;
  padding: 0 0.13rem;
  border-radius: 0.2rem 0rem;
  font-size: 11px;
  text-align: center;
  color: #ffffff;
  line-height: 0.5rem;
}

/*列表状态样式 1是未审批*/
.stateUnapproval {
  background: -webkit-linear-gradient(left, #FFC274, #FF5D3B);
}

/*列表状态样式 2是已通过*/
.statePassed {
  background: -webkit-linear-gradient(left, #66CB94, #28D3B0);
}

/*列表状态样式 3是已驳回*/
.stateRejected {
  background: -webkit-linear-gradient(left, #E02020, #FF431B);
}

/*列表头部红色重点信息*/
.headSpan {
  float: left;
  margin-right: 0.1rem;
  color: #FF5D3B;
}

/*列表头部小圆点*/
.dot {
  border-radius: 50%;
  width: 0.01rem;
  height: 0.01rem;
  border: 0.05rem solid black;
  float: left;
  margin-top: 0.24rem;
  margin-right: 0.1rem;
}

/*列表头部信息*/
.headInfo {
  margin-top: 0.4rem;
  margin-left: 0.4rem;
  font-size: 15px;
  font-weight: bold;
}

/*列表详情*/
.listDetail {
  position: relative;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  padding-bottom: 0.5rem;
}

.listDetail:after {
  content: "";
  display: block;
  clear: both;
}

/*    申请人信息DIV*/
.proposerDiv {
  margin-left: 0.5rem;
  font-size: 13px;
  color: #999999;
  float: left;
}

/*分割线1*/
.rule1 {
  position: absolute;
  left: 2.3rem;
  top: 0.1rem;
  border: 0.01rem solid #f7f7f7;
  height: 1rem;
  float: left;
}

/*分割线2*/
.rule2 {
  position: absolute;
  left: 5.3rem;
  top: 0.1rem;
  border: 0.01rem solid #f7f7f7;
  height: 1rem;
  width: 0.01rem;
  float: left;
}

/*装修预算和改造户型*/
.budgetDiv, .remouldDiv {
  font-size: 13px;
  color: #999999;
  margin-left: 1.68rem;
  float: left;
}

/*   装修预算 */
.budgetDiv {
  width: 1.5rem;
  text-align: center;
}

/*    分割线2*/
/* 申请人取值DIV*/
.proposerValueDiv {
  margin-top: 0.3rem;
  font-size: 14px;
  color: #FF5D3B;
  height: 0.5rem;
  float: left;
  /*margin-left: 0.6rem;*/
  width: 2rem;
  /*border: 1px solid red;*/
  text-align: center;
}

/*装修预算和改造户型取值*/
.budgetValueDiv, .remouldValueDiv {
  font-size: 14px;
  color: #FF5D3B;
  margin-top: 0.3rem;
  float: left;
  width: 2rem;
  /*border: 1px solid red;*/
  text-align: center;
}

.budgetValueDiv {
  margin-left: 0.8rem;
}

.remouldValueDiv {
  margin-left: 1.3rem;
}

/*    分割线3*/
.rule3 {
  border: 0.01rem solid #efefef;
  width: 100%;
  height: 0rem;
  margin-top: 0.1rem;
}

/*    尾部DIV*/
.listFloor {
  margin-left: 0.5rem;
  margin-top: 0.35rem;
  height: 0.73rem;
  line-height: 0.73rem;
}

.listFloor:after {
  content: "";
  display: block;
  clear: both;
}

/*    尾部 创建方式的createMethodsDiv*/
.createMethodsDiv {
  font-size: 13px;
  float: left;
  color: #999999;
}

/*    尾部 创建时间的createTimeDiv*/
.createTimeDiv {
  font-size: 13px;
  float: left;
  margin-left: 0.2rem;
  color: #999999;
  /*margin-top: 0.05rem;*/
}

/*    下部按钮*/
.btn1, .btn2 {
  float: right;
  margin-right: 0.7rem;
  height: 0.82rem;
  width: 2.3rem;
  border-radius: 0.2rem;
  border: 0rem solid transparent;
  box-shadow: none;
  line-height: 0.7rem;
  font-size: 13px;
}

/*按钮*/
.btn1 {
  background: -webkit-linear-gradient(left, #FFC274, #FF5D3B);
  color: white;
}

.btn2 {
  padding: 0.01rem;
  border: 0.03rem solid transparent;
  background-image: linear-gradient(white, white),
  linear-gradient(to right, #FFC274, #FF5D3B);
  background-clip: padding-box, border-box;
  background-origin: border-box;
  color: #FF5D3B;
}
</style>
